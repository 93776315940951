<template>
<vs-row v-if="$parent.user != null" style="padding-top: 0px;" class="store" justify="space-between">

    

    <vs-dialog v-model="themeChangeDialog" blur prevent-close :loading="themeChangeDialogLoading">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Attenzione!
            </h4>

        </template>

        <div class="con-form">
            <vs-row justify="center">
                <p style="margin: 0;">Il cambio di tema comporta una rielaborazione di tutte le pagine aggiunte fino ad ora. Nessun contenuto verrà perso ma la formattazione dei testi verrà ripristinata con quella originaria.</p>
            </vs-row>
            <vs-row justify="center">
                <p style="margin: 0;">Questa operazione può richiedere un po' di tempo, sei sicuro di voler proseguire?</p>
            </vs-row>
            <vs-row justify="center" style="margin-top: 20px; margin-bottom: 20px;">
                <img style="width: 40%;" src="../assets/book_render.png" />
            </vs-row>
        </div>

        <template #footer>
            <vs-row justify="space-between">
                <vs-button style="width: 49%; margin:0;" transparent @click="changeTheme(false)">
                    Torna indietro
                </vs-button>
                <vs-button style="width: 49%; margin:0;" @click="changeTheme(true)">
                    Procediamo!
                </vs-button>
            </vs-row>

        </template>
    </vs-dialog>

    <vs-dialog v-model="confirmProductUpgradeDialog" blur prevent-close>
        <template #header>
            <h4 style="margin-bottom: 0;">
                Complimenti!
            </h4>

        </template>

        <div class="con-form">
            <vs-row justify="center">
                <p style="margin: 0;">Hai raggiunto un numero di pagine sufficienti per poter proseguire al checkout. Ora puoi scegliere se con continuare la configurazione del tuo prodotto o procedere con l'acquisto.</p>
                <p style="margin:0;">Ricorda che se decidi di procedere al checkout, non sarà più possibile tornare alla configurazione per modificare il prodotto.</p>
            </vs-row>
        </div>

        <template #footer>
            <vs-row justify="space-between">
                <vs-button style="width: 49%; margin:0;" transparent @click="() => {confirmProductUpgradeDialog = false; layoutChooseDialog = true;}">
                    Continua la configurazione
                </vs-button>
                <vs-button style="width: 49%; margin:0;" @click="checkout()">
                    Procedi al checkout
                </vs-button>
            </vs-row>

        </template>
    </vs-dialog>

    <vs-dialog v-model="layoutChooseDialog" prevent-close class="chooseLayout">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Scegli un <b>modello</b>
            </h4>

        </template>

        <vs-row v-if="product != null">

            <vs-col w="3">
                <vs-select placeholder="Seleziona.." label="Tema" block v-model="layoutThemeTemp">
                    <vs-option v-for="theme in product.themes" :key="'theme-' + theme.id" :label="theme.title" :value="theme.id">
                        {{ theme.title }}
                    </vs-option>
                </vs-select>
            </vs-col>

        </vs-row>
        <vs-row>
            <vs-col w="4" v-for="layout in layouts" :key="'layout-' + layout.id">
                <div @click="layoutChooseDialogValue = layout.id" class="image-box-sel" :class="{'selected': layout.id == layoutChooseDialogValue}" :style="{'background-image': 'url(' + layout.previewFileUrl + ')'}"></div>
            </vs-col>
        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="addPage()" :disabled="layoutChooseDialogValue == null || pageFlipping || goingToPage">
                    Conferma selezione
                </vs-button>
            </div>
        </template>
    </vs-dialog>

   

    <vs-dialog v-model="elementChooseDialog" prevent-close class="chooseLayout">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Scegli un <b>elemento</b>
            </h4>

        </template>

        <!-- SORT AND FILTER -->
        <vs-row justify="space-between">
            <vs-button v-if="elementReference != null && elementReference.type == 'IMAGE'" @click="$refs.imageInput.click()" :loading="uploading">
                <i class='bx bx-upload bx-sm'></i> Carica immagine
            </vs-button>
            <vs-button v-if="elementReference != null && elementReference.type == 'TEXT'" @click="skipPostSelection(elementReference)">
                <i class='bx bxs-edit-alt bx-sm'></i> Testo personalizzato
            </vs-button>
            <vs-tooltip bottom shadow not-hover v-model="activeFilterTooltip" :loading="postLoading">
                <vs-button icon transparent @click="activeFilterTooltip = true"><i class='bx bx-filter-alt bx-sm'></i></vs-button>
                <template #tooltip>
                    <vs-row style="margin-top: 25px;">
                        <vs-input type="date" label="Dal" block v-model="postFilter.fromDate"></vs-input>
                    </vs-row>
                    <vs-row style="margin-top: 25px;">
                        <vs-input type="date" label="Al" block v-model="postFilter.toDate"></vs-input>
                    </vs-row>

                    <vs-row>
                        <vs-button block transparent v-if="postFilter.sort == 'DESC'" @click="postFilter.sort = 'ASC'"><i class='bx bx-sort-up bx-sm'></i> Decrescente</vs-button>
                        <vs-button block transparent v-if="postFilter.sort == 'ASC'" @click="postFilter.sort = 'DESC'"><i class='bx bx-sort-down bx-sm'></i> Crescente</vs-button>
                    </vs-row>
                    <vs-row>
                        <vs-button block @click="resetFilters"><i class='bx bx-eraser bx-sm'></i> Pulisci filtri</vs-button>
                    </vs-row>
                </template>
            </vs-tooltip>

        </vs-row>
        <vs-row class="posts" style="height: 50vh; overflow-y: auto; overflow-x: hidden; padding: 20px;">
            <template v-if="posts != null">
                <!-- ONLY IMAGE -->
                <template v-if="elementReference != null && elementReference.type == 'IMAGE'">

                    <vs-col w="3" v-for="post in posts" :key="'post-' + post.id">
                        <div @click="elementChooseDialogValue = post" class="image-box-sel" :class="{'selected': post == elementChooseDialogValue}" :style="{'background-image': 'url(' + post.imageUrl + ')'}"></div>
                    </vs-col>
                </template>

                <!-- ONLY TEXT -->
                <template v-if="elementReference != null && elementReference.type == 'TEXT'">
                    <vs-row v-for="(p,k) in posts" :key="'post-' + k">
                        <vs-col w="12">
                            <Post v-on:click.native="elementChooseDialogValue = p" :title="(p.emozione != null ? ('#' + p.emozione) : p.momento != null ? ('Sta' + p.momento) : '')" :caption="p.caption" :emoji="(p.momento != null ? p.momento : null)" :image="p.imageUrl" :created="p.createdAt" :selected="elementChooseDialogValue == p" />

                        </vs-col>
                    </vs-row>
                </template>
            </template>

        </vs-row>
        <vs-row justify="center" style="margin-top: 20px;">
            <vs-pagination v-model="currentPostsPage" :length="totalPostPages" />
        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block :disabled="elementChooseDialogValue == null">
                    Conferma selezione
                </vs-button>
            </div>
        </template>
    </vs-dialog>

   
    <vs-row style="height:calc(80vh); width: 15%;" justify="flex-start" align="flex-end" direction="row" class="pages-preview">
        <!-- previews-->
        <template v-if="orderDraft && orderDraft.pages">
            <draggable class="pois" :list="orderDraft.pages" group="pages" @start="drag = true" @end="onDragEnd" v-bind="dragOptions" :move="onMoveCallback">
                    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                        <div class="page-preview" v-for="page in orderDraft.pages" :key="page.id" :style="{'background-image': 'url(' + page.previewFileUrl + ')', width: 200 + 'px', height: product.height/(product.width/200) + 'px'}" @click="goToPage(page)" :class="{'active': orderDraft.pages && orderDraft.pages[pageNo].id == page.id}" :ref="'pagePreview-' + page.id">
                            <vs-row class="page-preview-tools">
                                <vs-button @click="deletePage(page)" size="small" transparent :disabled="layout && layout.layout.type != 'Page'">
                                    <i class='bx bx-trash bx-sm' style="color: white;"></i>
                                </vs-button>
                                <p>Pagina {{ page.pageNo }}</p>
                            </vs-row>
                        </div>
                    </transition-group>
            </draggable>
            
        </template>
        
            
    </vs-row>
    <vs-row style="height:calc(80vh - 100px); width: 85%;" justify="center" align="flex-start" class="builder-container">
        

            <vs-row justify="center" align="center" style="gap: 50px; margin-bottom: 50px;" class="builder-actions">

                <vs-button :disabled="product == null || (orderDraft.pages.length - 1) >= product.maxPages" @click="addPageMiddleware" class="add-page">
                    <i class='bx bx-plus bx-sm'></i> <span class="desktop-only">Aggiungi</span> Pagina
                </vs-button>

                <vs-button @click="savePageDraft(orderDraft.pages[pageNo])" class="save-page">
                    <i class="bx bx-save bx-sm"></i> Salva
                </vs-button>
                
                <vs-row align="center" style="gap: 10px; width:unset!important;" class="page-navigation">
                <vs-button circle icon @click="prevPage"><i class='bx bx-chevron-left bx-sm'></i></vs-button>
                <span class="page-num">
                    Pagina {{ pageNo + 1 }} di {{ orderDraft ? orderDraft.pages.length : 0 }}
                </span>
                <vs-button circle icon @click="nextPage"><i class='bx bx-chevron-right bx-sm'></i></vs-button>
                </vs-row>

                <vs-tooltip bottom shadow not-hover v-model="activeCheckoutTooltip" v-if="requiredActions.length == 0">
                    <vs-button icon @click="activeCheckoutTooltip = !activeCheckoutTooltip" :loading="checkoutLoading">
                        <i class='bx bx-cart-alt bx-sm'></i> <span class="desktop-only">Procedi al</span> Checkout
                    </vs-button>
                    <template #tooltip>
                        <div class="content-tooltip">
                            <h4 class="center">
                                Conferma
                            </h4>
                            <p>
                                Una volta eseguito il checkout non sarà più possibile modificare la configurazione del tuo prodotto, sei sicuro di voler proseguire?
                            </p>
                            <footer>
                                <vs-button @click="activeCheckoutTooltip=false" transparent block style="margin: 0; margin-bottom: 5px;">
                                    Annulla
                                </vs-button>
                                <vs-button @click="() => { checkout(); }" block style="margin: 0;">
                                    Prosegui
                                </vs-button>
                            </footer>
                        </div>
                    </template>
                </vs-tooltip>

                <vs-tooltip border-thick color="#b648f6" v-else>
                    <vs-button icon disabled>
                        <i class='bx bx-cart-alt bx-sm'></i> <span class="desktop-only">Procedi al</span> Checkout
                    </vs-button>
                    <template #tooltip>
                        <template v-if="requiredActions[0] != undefined">
                            <template v-if="requiredActions[0] > 0">
                                <!-- PAGINE DA AGGIUNGERE-->
                                <template v-if="Math.abs(requiredActions[0]) > 1">
                                    <!-- PLURALE-->
                                    Sono necessarie ancora {{ Math.abs(requiredActions[0]) }} pagine.
                                </template>
                                <template v-else>
                                    <!-- SINGOLARE-->
                                    È necessaria ancora {{ Math.abs(requiredActions[0]) }} pagina.
                                </template>
                            </template>
                            <template v-else>
                                <!-- PAGINE DA TOGLIERE-->
                                <template v-if="Math.abs(requiredActions[0]) > 1">
                                    <!-- PLURALE-->
                                    È necessario rimuovere {{ Math.abs(requiredActions[0]) }} pagine.
                                </template>
                                <template v-else>
                                    <!-- SINGOLARE-->
                                    È necessario rimuovere {{ Math.abs(requiredActions[0]) }} pagina.
                                </template>
                            </template>
                        </template>

                        <template v-if="requiredActions[1] != undefined">
                            <br />
                            <template v-if="requiredActions[1] > 0">
                                <!-- PAGINE DA AGGIUNGERE-->
                                <template v-if="Math.abs(requiredActions[0]) > 1">
                                    <!-- PLURALE-->
                                    In alternativa aggiungere {{ Math.abs(requiredActions[1]) }} pagine.
                                </template>
                                <template v-else>
                                    <!-- SINGOLARE-->
                                    In alternativa aggiungere {{ Math.abs(requiredActions[1]) }} pagina.
                                </template>
                            </template>
                            <template v-else>
                                <!-- PAGINE DA TOGLIERE-->
                                <template v-if="Math.abs(requiredActions[1]) > 1">
                                    <!-- PLURALE-->
                                    In alternativa rimuovere {{ Math.abs(requiredActions[1]) }} pagine.
                                </template>
                                <template v-else>
                                    <!-- SINGOLARE-->
                                    In alternativa rimuovere {{ Math.abs(requiredActions[1]) }} pagina.
                                </template>
                            </template>
                        </template>

                    </template>
                </vs-tooltip>

            </vs-row>

            <Builder ref="builder" :builder-data="{product: product, layout: layout, userMode: true, heightSubtract: (window.screen.availHeight*0.33)}" v-if="product && layout"/>

    </vs-row>
    
</vs-row>
</template>

<script>
import draggable from 'vuedraggable';

import {
    apiCall,
    baseUrl
} from '../client';

import axios from 'axios';
import {
    s3Client
} from '../s3Client';

import Builder from './Admin/Builder.vue';

import Post from '../components/Post';

export default ({
    props: {
        productId: Number
    },
    components: {
        Post,
        Builder,
        draggable
    },

    setup() {

    },

    data() {
        return {
            layouts: [],
            layoutChooseDialog: false,
            layoutChooseDialogValue: null,
            layoutTheme: '',
            layoutThemeTemp: '',
            pagesPreviews: [null, ],
            baseUrl,

            elements: [],

            posts: [],

            elementChooseDialog: false,
            elementChooseDialogValue: null,
            elementReference: null,


   

            product: null,
            productVariant: null,

            qrCode: '',

            fonts: [],

            themeChangeDialog: false,
            themeChangeDialogLoading: false,

            activeFilterTooltip: false,

            postFilter: {
                sort: 'DESC',
                fromDate: null,
                toDate: null,
                showHidden: true
            },

            currentPostsPage: 1,
            totalPostPages: 1,

            uploading: false,

            hasBackCover: false,
            hasEmptyPage: false,

            postLoading: false,

            canExit: false,

            requiredActions: [0],

            activeCheckoutTooltip: false,
            checkoutLoading: false,

            pageFlipping: false,
            goingToPage: false,

            confirmProductUpgradeDialog: false,

            rendering: false,

            // NEW ITEMS: TODO REMOVE OLD ON TOP IF NO LONGER USED

            orderDraft: null,
            pageNo: null,
            layout: null,
            window,
            drag: false

        }
    },

    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
                delay: 250,
                delayOnTouchOnly: true,
            };
        }

    },

    methods: {

        async onDragEnd(evt){
            this.drag = false;

            console.log('DRAG END', evt);

            // patch page with new pageNo
            const newIndex = evt.newIndex;
            let page = this.orderDraft.pages[newIndex];
            if(!page){
                return;
            }

            

            const oldPageNo = page.pageNo;

            page.pageNo = newIndex + 1;
            console.log(oldPageNo + ' MOVED TO '+ page.pageNo);

            await this.savePageOrderOnly(page);

            // call getOrderDraft
            const orderDraftResponse = await this.getOrderDraft();
            if(orderDraftResponse.status != 200){
                this.$vs.notification({
                    title: 'Errore',
                    text: 'Impossibile aggiornare l\'ordine',
                    color: 'danger',
                    position: 'top-right'
                });
                return;
            }

            // override pageNo only
            this.orderDraft.pages.forEach((e, i) => {
                console.log('New page no for page', e.id, 'is', orderDraftResponse.data.pages.find(p => p.id == e.id).pageNo);
                e.pageNo = orderDraftResponse.data.pages.find(p => p.id == e.id).pageNo;
            });

            this.orderDraft.__ob__.dep.notify();
        },

        onMoveCallback(evt) {
            // understand what are proibithed indexes: pages with Types different from Page ARE NOT ALLOWED
            let proibithedIndexes = this.orderDraft.pages.map((e, i) => {
                if (e.type != 'Page') {
                    return i;
                }
            }).filter(e => e != undefined);

            console.log(proibithedIndexes)

            if (proibithedIndexes.includes(evt.draggedContext.futureIndex)) {
                return false;
            }

            if(proibithedIndexes.includes(evt.draggedContext.index)){
                return false;
            }

            return true;
        },

        async getOrderDraft(){
            var orderDraftResponse = await apiCall('GET', '/Order/Draft/' + this.$route.params.orderDraftId);
            return orderDraftResponse;
        },

        async loadDraft(){
            // obtain the orderDraft
            var orderDraftResponse = await this.getOrderDraft();
            if (orderDraftResponse.status == 200) {
                var draft = orderDraftResponse.data;

                await Promise.all(draft.pages.map(async (p) => {
                    var pictureId = p.id;
                    if (pictureId != null) {
                        const signedUrl = await s3Client.getSignedDownloadURL({name: pictureId});

                        const exists = await s3Client.fileExists(signedUrl);

                        if(exists){
                            p.previewFileUrl = signedUrl.url;
                        } else {
                            // try to load using || p.previewPictureId
                            const defaultSignedUrl = await s3Client.getSignedDownloadURL({name: p.previewPictureId});

                            const existsDefault = await s3Client.fileExists(defaultSignedUrl);

                            if(existsDefault){
                                p.previewFileUrl = defaultSignedUrl.url;
                            }
                        }
                    }
                }));

                console.log('DRAFT', draft);

                this.orderDraft = draft;
            } else {
                this.$router.push({
                    name: 'feed'
                });
                return;
            }
        },

        async savePageOrderOnly(page){
            // 2. Patch
            const result = await apiCall('PATCH', '/Order/' + this.orderDraft.id + '/Pages/' + page.id, {
                pageNo: page.pageNo
            });

            if(!result || result.status != 200){
                this.$vs.notification({
                    title: 'Errore',
                    text: 'Impossibile salvare la pagina',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },

        async savePageDraft(page){
            await new Promise((resolve) => {
                // 1. generate preview
                this.$refs.builder.savePreviewPicture(page.id).then(async () => {
                    // get page preview
                    const signedUrl = await s3Client.getSignedDownloadURL({name: page.id});

                    const exists = await s3Client.fileExists(signedUrl);
                    if(exists){
                        // fetch it using axios as blob, then generate url and set it: this prevents the browser from showing no image before loading
                        const imageResult = await axios.get(signedUrl.url, {
                            responseType: 'blob'
                        });

                        const blob = new Blob([imageResult.data], {type: imageResult.headers['content-type']});

                        page.previewFileUrl = URL.createObjectURL(blob);
                        page.__ob__.dep.notify();
                    }

                    // 2. Patch
                    const result = await apiCall('PATCH', '/Order/' + this.orderDraft.id + '/Pages/' + page.id, {
                        jsonData: this.$refs.builder.getSerializedData(),
                        pageNo: page.pageNo
                    });

                    if(!result || result.status != 200){
                        this.$vs.notification({
                            title: 'Errore',
                            text: 'Impossibile salvare la pagina',
                            color: 'danger',
                            position: 'top-right'
                        });
                    }

                    resolve();
                });
            });
            
            
        },

        async nextPage() {
            if (this.pageNo + 1 < this.orderDraft.pages.length) {
                this.goToPage(this.pageNo + 1);
            }
        },

        async prevPage() {
            if (this.pageNo > 0) {
                this.goToPage(this.pageNo - 1);
            }
        },

        async addPage(){
            let selectedLayout = this.layouts.find(e => e.id == this.layoutChooseDialogValue);
            // call PUT /Order/{id}/Page
            const result = await apiCall('PUT', '/Order/' + this.orderDraft.id + '/Page', {
                layoutId: selectedLayout.id,
                pageNo: this.pageNo + 1,
            });
            

            if(result.status == 200){
                // save the page if exists
                if(this.layout != null){
                    await this.savePageDraft(this.orderDraft.pages[this.pageNo]);
                }

                this.orderDraft.pages.push({
                    ...result.data,
                    previewFileUrl: selectedLayout.previewFileUrl,
                });
                this.layoutChooseDialog = false;

                // reload order pages draft
                await this.loadDraft();
                this.$nextTick(() => {
                    this.goToPage(result.data, true);
                });
            } else {
                this.$vs.notification({
                    title: 'Errore',
                    text: 'Impossibile aggiungere la pagina',
                    color: 'danger',
                    position: 'top-right'
                });
            }

        },

        async goToPage(page, skipSave = false){
            
            if(this.pageNo > 0){
                // if page is already current page
                if(typeof page == 'object' && this.orderDraft.pages[this.pageNo].id == page.id){
                    return;
                }

                if(typeof page == 'number' && this.pageNo == page){
                    return;
                }
            }

            if(typeof page == 'number'){
                page = this.orderDraft.pages[page];
            } else if(typeof page == 'object'){
                page = this.orderDraft.pages.find(e => e.id == page.id);
            }
            console.log(this.$refs['pagePreview-' + page.id])
            const loading = this.$vs.loading({
                target: this.$refs['pagePreview-' + page.id] ? this.$refs['pagePreview-' + page.id][0] : null,
                color: 'dark',
                text: 'Caricamento...',
                type: 'scale',

            });

            // wait 500ms
            await new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 150);
            });

            if(this.layout != null && this.orderDraft.pages[this.pageNo] != null && !skipSave){
                await this.savePageDraft(this.orderDraft.pages[this.pageNo]);
            }

            this.layout = null; // destroy builder

            this.$nextTick(() => {
                this.pageNo = this.orderDraft.pages.indexOf(page);

                console.log('Dipatch', this.pageNo);

                loading.close();
            });

            
        },

        addPageMiddleware(){
            // check if we are going into next product variant
            if(this.orderDraft.pages.length == this.productVariant.pages){
                this.confirmProductUpgradeDialog = true;
                return;
            }

            this.layoutChooseDialog = true;
        },

        async deletePage(page) {
            // call delete method, on success remove it also from orderDraft.pages
            const result = await apiCall('DELETE', '/Order/' + this.orderDraft.id + '/Pages/' + page.id);
            if(result.status == 200){
                this.orderDraft.pages = this.orderDraft.pages.filter(e => e.id != page.id);
                var p = this.pageNo - 1;
                if(p < 0){
                    p = 0;
                }
                // force builder to destroy
                this.layout = null;

                // reload orderDraft pages
                await this.loadDraft();

                this.$nextTick(() => {
                    this.goToPage(p, true);
                });
            } else {
                this.$vs.notification({
                    title: 'Errore',
                    text: 'Impossibile eliminare la pagina',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },


        async checkout() {
            this.canExit = true;
            this.activeCheckoutTooltip = false;
            this.checkoutLoading = true;

            // first save draft
            await this.savePageDraft(this.orderDraft.pages[this.pageNo]);

            setTimeout(() => {
                var vm = this;
                this.$router.push({
                    name: 'checkout',
                    params: {
                        orderDraftId: vm.orderDraft.id,
                        product: vm.product,
                        orderDraft: vm.orderDraft
                    }
                });
            }, 1000); // in this way the tooltip has time to close

        },

        async getPosts(type) {
            this.postLoading = true;
            var p = JSON.parse(JSON.stringify(this.postFilter)); // deep copy
            p.page = this.currentPostsPage - 1; // for us it starts from 0
            p.type = type;
            var result = await apiCall('POST', '/Post', p);
            this.postLoading = false;
            if (result.status == 200) {
                var newPosts = result.data.posts.map(function (e) {
                    return {
                        id: e.id,
                        emozione: e.emotion,
                        momento: null,
                        caption: e.text,
                        imageUrl: e.fileId != null ? (baseUrl + '/Post/Media/' + e.fileId) : null,
                        createdAt: new Date(e.createdAt),
                        fileId: e.fileId
                    }
                });
                this.posts = newPosts;
                this.totalPostPages = Math.ceil(result.data.total / 8);
            }
        },

        
        evaluateProductVariants(pages) {
            console.log('EVALUATE', pages);
            if (this.product.productVariants == undefined) {
                return;
            }

            var pv = this.product.productVariants.find(e => e.pages >= pages);
            console.log('PV', pv);
            this.productVariant = pv != undefined ? pv : this.productVariant;

            this.requiredActions = [];

            var perfectMatch = this.product.productVariants.find(e => e.pages == pages);
            if (perfectMatch != null) {
                return;
            }

            var greater = this.product.productVariants.find(e => e.pages > pages);
            if (greater != null) {
                var gDelta = greater.pages - pages;
                this.requiredActions.push(gDelta);
            }

            var lower = this.product.productVariants.find(e => e.pages < pages);
            if (lower != null) {
                var gLower = lower.pages - pages;
                this.requiredActions.push(gLower);
            }
        }

    },

    watch: {
        requiredActions(n) {
            console.log('REQUIRED', n);
        },

        layoutThemeTemp(n) {
            if (n != this.layoutTheme) {
                // ask to user if is sure to change the theme
                this.themeChangeDialog = true;
            }
        },


        'orderDraft.pages'(n) {
            this.evaluateProductVariants(n.length) // -1 because the first one is null always
        },

        

        postFilter: {
            deep: true,
            handler: function () {
                if (this.currentPostsPage > 0) { // se era a una pagina maggiore di zero la imposto a 0, e questo in automatico ritriggera il caricamento del feed
                    this.currentPostsPage = 0;
                } else { // se invece era già a 0, ricarico il feed manualmente
                    this.getPosts(this.elementReference.type);
                }

            }
        },

        currentPostsPage() {
            this.getPosts(this.elementReference.type);
        },

        async pageNo(n){
            const result = await apiCall('GET', '/Order/Draft/' + this.orderDraft.id + '/Page/' + this.orderDraft.pages[n].id);
            if(result.status == 200){
                this.layout = result.data;
            }
        }
    },

    beforeRouteLeave(to, from, next) {
        if (this.canExit || this.$parent.user == null) {
            next();
            return;
        }
        const answer = window.confirm('Se abbandoni questa pagina tutte le modifiche verranno perse.')
        if (answer) {
            next()
        } else {
            next(false)
        }
    },

    async mounted() {
        if (this.productId == null || this.productId < 1) {
            this.$router.push({
                name: 'feed'
            }); // tentativo di accesso diretto
            return;
        }
        if(this.$route.params.orderDraftId == null){
            // PUT OrderDraft
            var res = await apiCall('PUT', '/Order/Draft', {
                productId: this.productId
            });
            console.log(res);
            if (res.status == 200) {
                // replace in current route
                this.$router.replace({
                    name: 'store',
                    params: {
                        productId: this.productId,
                        orderDraftId: res.data.id
                    }
                });
            }
        }

        await this.loadDraft();

        if(this.orderDraft.pages.length){
            this.$nextTick(() => {
                this.goToPage(0, true);
            });
        }


        result = await apiCall('GET', '/Font');
        if (result.status == 200) {
            this.fonts = result.data;
        }

        result = await apiCall('GET', '/Product');
        if (result.status == 200) {
            this.product = result.data.find(e => e.id == this.productId);
            console.log(this.product);
            this.evaluateProductVariants(this.orderDraft.pages.length )

            // find the variant with higher number of pages and set product.maxPages to that value
            this.product.maxPages = Math.max.apply(Math, this.product.productVariants.map(function (o) {
                return o.pages;
            }));

            // find the variant with lower number of pages and set it as current productVariant
            this.productVariant = this.product.productVariants.find(e => e.pages == Math.min.apply(Math, this.product.productVariants.map(function (o) {
                return o.pages;
            })));
        }

        var result = await apiCall('GET', '/Product/Layout');
        console.log(result);
        if (result.status == 200) {
            let layouts = result.data.filter(e => e.product && e.product.id == this.productId);

            await Promise.all(layouts.map(async (l) => {
                if (l.previewPictureId != null) {
                    const signedUrl = await s3Client.getSignedDownloadURL({name: l.previewPictureId});

                    const exists = await s3Client.fileExists(signedUrl);
                    if(exists){
                        l.previewFileUrl = signedUrl.url;
                    }
                }
            }));

            this.layouts = layouts;
        }

        console.log('layouts',this.layouts);

    },
})
</script>

<style>

.image-box-sel {
    padding-bottom: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    opacity: 0.6;
}

.image-box-sel:hover {
    opacity: 0.8;
    cursor: pointer;
}

.image-box-sel.selected {
    opacity: 1;
}

.image-box-sel::before,
.image-box-sel::after {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
}

.image-box-sel::before {
    backdrop-filter: blur(10px) brightness(0.8);
}

.image-box-sel::after {
    background-image: inherit;
    background-size: contain;
    background-position: inherit;
    background-repeat: inherit;
}

.chooseLayout .vs-dialog {
    width: 60%;
}

.preview-container {
    width: 500px;
    height: 250px;
    position: relative;
    overflow: hidden;
    text-align: center;
}

.preview-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    filter: blur(10px);
    transform: scale(1.1);
}

.preview-image {
    max-width: 100%;
    max-height: 100%;
}

.store .vs-card__img {
    max-height: 150px;
}

.store .vs-card__img:first-child:not(img) {
    height: 150px;
}


.disabled-section {
    pointer-events: none!important;
    opacity: 0.6;
}

/* .page with child .store 's header padding 0 */

.page:has(.store) header {
    padding-bottom: 10px;
}

.page:has(.store){
    overflow: unset!important;
    height: unset!important;
}

.page-preview {
    background-size: cover;
    border-radius: 5px;
    overflow: hidden;
    filter: brightness(0.7);
    transition: filter 0.3s;
}
/* on hover make it darker using filter */
.page-preview:hover {
    filter: brightness(1);
    cursor: pointer;
}

.page-preview.active {
    filter: brightness(1);
}



.pages-preview {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    gap: 20px;
    flex-direction: column!important;
    align-items: flex-start!important;
    justify-content: flex-start!important;
}

.page-preview-tools {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-bottom: 45px;
    right: 0;
    /* fradient background */
    background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.4) 40%, rgba(0,0,0,0) 100%);
    color: white;

}
.page-preview-tools p{
    margin: 0;
    /* soft shadow */

    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    bottom: 10px;
}

.page-preview-tools .vs-button {
    position: absolute;
    bottom: 0;
    right: 0;
}

.page-preview:not(.active) .page-preview-tools .vs-button {
    display: none;
}

.page-preview:has(.vs-loading) {
    cursor: not-allowed;
    pointer-events: none!important;
}

/* from mobile set store flex-direction to column, and set pages-preview to 100% width and flex-direction to row, overflow-y to hidden and overflow-x to auto, width of builder-container to 100% */
@media (max-width: 768px) {
    .store {
        flex-direction: column-reverse!important;
        height: unset!important;
    }

    .pages-preview {
        width: 100%!important;
        flex-direction: column!important;
        overflow-y: hidden;
        overflow-x: auto;
        height: 20vh!important;
    }

    /* scrollbar of pages-preview should be thin and minimal */
    .pages-preview {
        scrollbar-width: thin;
        scrollbar-color: #bbb transparent;
    }
    .pages-preview::-webkit-scrollbar {
        height: 10px;
    }
    .pages-preview::-webkit-scrollbar-track {
        background: transparent;
    }
    .pages-preview::-webkit-scrollbar-thumb {
        background-color: #919191;
        border-radius: 10px;
    }


    .builder-container {
        width: 100%!important;
        height: unset!important;
    }

    .builder-actions {
        gap: 10px!important;
        margin-bottom: 20px!important;
    }

    /* change order of save-page and add-page, page-navigation and checkout */
    /* order must be: save-page,add-page, checkout, page-navigation */
    .builder-actions .add-page {
        order: 2;
    }
    .builder-actions .save-page {
        order: 1;
    }
    .builder-actions .page-navigation {
        order: 4;
    }

    /* footer disappears */
    .page:has(.store) .footer {
        display: none;
    }
}

.store span.desktop-only {
    margin-right: 3px;
}
</style>
